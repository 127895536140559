import { Dom, Any } from "@kizmann/pico-js";

if ( window.location.href.match(/charts.hk24\.de/) ) {
    document.domain = "hk24.de";
}

window.chartUpdate = function(container) {

    if ( ! window.parent || ! container) {
        return;
    }

    try {

        let watchResize = Any.debounce(() => {

            window.parent.postMessage(JSON.stringify({
                'docEl': container, 'docHeight': Dom.find('#' + container).height() + 10
            }), '*');

        }, 200);

        $(window).resize(watchResize).resize();

    } catch (err) {
        if (window.DEV_MODE) {
            console.error(err.message)
        }
    }
};

window.chartHeightMessage = function(event) {

    let data = null;

    try {
        if ( event.data !== 'print' ) {
            data = JSON.parse(event.data);
        }
    } catch (e) {
        console.error(e.message);
    }

    if ( ! data || ! event.origin.match(/(charts\.hk24\.de|dievisualisten\.com|hk24\.local)/) ) {
        return;
    }

    try {

        let width = Dom.find('#hk-' + data.docEl).width();

        Dom.find('#hk-' + data.docEl).css({
            height: data.docHeight + 'px', width: width + 'px'
        });

        Dom.find(window).on('beforeprint', () => {

            Dom.find('#hk-' + data.docEl).css({
                height: data.docHeight + 'px', width: (width - 165) + 'px'
            });

        });

        Dom.find(window).on('afterprint', () => {

            Dom.find('#hk-' + data.docEl).css({
                height: data.docHeight + 'px', width: width + 'px'
            });

        });

    } catch (err) {
        console.error(err.message)
    }
};

Dom.find(window).on('message', window.chartHeightMessage);

// if ( window.addEventListener ) {
//     window.addEventListener('message', window.chartHeightMessage, false);
// }
